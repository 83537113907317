import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/OpenHAB_IP_Camera_Binding/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Node-RED Setup under openHABian",
  "path": "/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHABian aims to provide a self-configuring Linux system setup specific to the needs of every Raspberry Pi user. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_openHABian.png",
  "social": "/images/Search/AU_SearchThumb_openHABian.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Node-RED Setup under openHABian' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_openHABian.png' twitter='/images/Search/AU_SearchThumb_openHABian.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/openHABian_auf_Raspberry_Pi/Node-RED_Setup/' locationFR='/fr/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "node-red-setup-under-openhabian",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-setup-under-openhabian",
        "aria-label": "node red setup under openhabian permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Setup under openHABian`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab-cli-tool"
        }}>{`OpenHAB CLI Tool`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setting-up-node-red"
        }}>{`Setting up Node-RED`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#troubleshooting"
            }}>{`Troubleshooting`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#manual-installation-optional"
        }}>{`Manual Installation (optional)`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "openhab-cli-tool",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-cli-tool",
        "aria-label": "openhab cli tool permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB CLI Tool`}</h2>
    <p>{`We want to set up the Node-RED flow that we were already working with in our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`Windows Installation`}</a>{`, so that we will be able to use all of the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/Projects/"
      }}>{`Node-RED Projects`}</a>{` we developed there. First we want to use the automatic installation offered by the OpenHAB CLI - `}<a parentName="p" {...{
        "href": "#manual-installation-optional"
      }}>{`below we also take a look`}</a>{` at how this can be done manually (in case that you are using a different LINUX distro for your Pi).`}</p>
    <p>{`First, login to your Pi via SSH (Windows user can use the free Terminal from `}<a parentName="p" {...{
        "href": "https://mobaxterm.mobatek.net/download.html"
      }}>{`Mobaterm`}</a>{` and open the CLI tool with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo openhabian-config`}</code>{`. Use your user password (on openHABian this is `}<strong parentName="p">{`openhabian`}</strong>{` by default) to execute the program and choose `}<em parentName="p">{`Optional Components`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6b86409ab9e58cf9b6d46e71c6702323/e9beb/OpenHabian_CLI_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABxElEQVQY0y2Ia2/SUABA+/N0Y8Y/YObji//Bv4BKoS2V5227QVsEtJitY4zBhoW2cG/v7QpXHkZdxkMGi0E/Go2bycnJyWE42JU8J4vdPdLTfZz3PdVDKvFUH6sE5QlSMdIDol1gDaN9HyoBUvxezLaeo6/MEBV/D/JrpEzbqaUDNiT3i+QXdnbpgIUNZu30zEpP2+mFDb7bYAXlTaD+mZRgJfzsFDE99H49KH5xVFJN0qZ06WrfbJU25eGZTBuANiXakC5q6UEj++lc7p+Az1buZmScGeyTOmS6bmnV18flN4HwEgvhfioyycSCxGuaZGmSDRKRUYYbA34sC2MQn4jRSy15HbxtVdinp5Bx3eK1r9FWtnPIOaZAP4KhJdmHfO9Y7FZF2xS8ehLXU/1zMOooU1KYYn0ZFFoV9vFJj/GJsaHFuV+Y+4UfI2M9KM+IPsPanOhXnnaF1P/haTNSuKHlVb/0c2w4Jr9bcxnTTMNK2D2IQjOGjnj3A2u9e4VMDh1x0OTQLR0jAk2e1OLQ5LoHUVzliokXu8cu81Ax7rGZLUEJCcqOmHsg5nbE/VB8LyT8Y/vWd2dbUO68xSv3OflRi/4FwWhVqBTo8cEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b86409ab9e58cf9b6d46e71c6702323/e4706/OpenHabian_CLI_02.avif 230w", "/en/static/6b86409ab9e58cf9b6d46e71c6702323/d1af7/OpenHabian_CLI_02.avif 460w", "/en/static/6b86409ab9e58cf9b6d46e71c6702323/b6582/OpenHabian_CLI_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6b86409ab9e58cf9b6d46e71c6702323/a0b58/OpenHabian_CLI_02.webp 230w", "/en/static/6b86409ab9e58cf9b6d46e71c6702323/bc10c/OpenHabian_CLI_02.webp 460w", "/en/static/6b86409ab9e58cf9b6d46e71c6702323/87ca7/OpenHabian_CLI_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b86409ab9e58cf9b6d46e71c6702323/81c8e/OpenHabian_CLI_02.png 230w", "/en/static/6b86409ab9e58cf9b6d46e71c6702323/08a84/OpenHabian_CLI_02.png 460w", "/en/static/6b86409ab9e58cf9b6d46e71c6702323/e9beb/OpenHabian_CLI_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6b86409ab9e58cf9b6d46e71c6702323/e9beb/OpenHabian_CLI_02.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then select Node-RED:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a54e33b07979259c857ef0639de95ee5/e9beb/OpenHabian_CLI_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABmklEQVQY0yXJ7W6TUADGce5SuzXRxMVEv5lsN+BHdWWnNYMOV7AD1vcDXVvXdNkyunXQQ2EUTjmwUZymlVanxngBZjX55Z88eaiXVpyxHdpxgYcZTDhyy2DCuCTv37A4YDBZdcUl710feJOsN3k7sl+MptQWwktb+oVri+vSVCtEWmFplZKhHGl83OenfT46L9xdCgvr8N6u/PXhb7/xJ4DxBfe8dUptocnMLi0IvOsKHkP7e7mv+2xcYMYsjfcA5rIeA3Ae+Fx2+pGdFz8kZ4fLQAn7+xvqCbVp4C9InDvlcCCY3d1wIHxzyuSC19s5s8tavbzVY41jxvi062n8zKnOrstzpxxo3EbzhNpEOBlXfhA4d2u3QylCcoLrs3E1NuVQF2NTjpAcDaUbQ0xw42eo3gfqd6J8NorPlB71Svd0NXOlbhtHtNkGZgdo9TeX8N2ok0VtgFo7ZgfozcxA3baOc6i1YzTp4RF9Kr1+UutQT6/ClAgfC9VUsb4mwrSkpCW4Lj5YO2g8EBv/5+pS1g9gqlh/xFfSZ84/xKUpy3AeaQkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a54e33b07979259c857ef0639de95ee5/e4706/OpenHabian_CLI_03.avif 230w", "/en/static/a54e33b07979259c857ef0639de95ee5/d1af7/OpenHabian_CLI_03.avif 460w", "/en/static/a54e33b07979259c857ef0639de95ee5/b6582/OpenHabian_CLI_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a54e33b07979259c857ef0639de95ee5/a0b58/OpenHabian_CLI_03.webp 230w", "/en/static/a54e33b07979259c857ef0639de95ee5/bc10c/OpenHabian_CLI_03.webp 460w", "/en/static/a54e33b07979259c857ef0639de95ee5/87ca7/OpenHabian_CLI_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a54e33b07979259c857ef0639de95ee5/81c8e/OpenHabian_CLI_03.png 230w", "/en/static/a54e33b07979259c857ef0639de95ee5/08a84/OpenHabian_CLI_03.png 460w", "/en/static/a54e33b07979259c857ef0639de95ee5/e9beb/OpenHabian_CLI_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a54e33b07979259c857ef0639de95ee5/e9beb/OpenHabian_CLI_03.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let the install script run:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9336ca39798e1a243905c637637dfd31/e9beb/OpenHabian_CLI_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABeklEQVQoz12SC47bMAxEfZo6tvUjJYr62pGT2Nnt/e9TaJ0CRYEBIQh80gzIIReO0dU15OLrGmLgnP22xbrGreVcmciQB89IHgDlJUSJVg4puZioVOYA5CFlSplCdLlQiJgrx+RCdKX4mBz+xS4N76/n1/fxfj+Oo7W93FvJ2QMKzzpG54Mlb8ijZ7BO/Q/vj/U49/Pcn8+VyGgzKz0bEMS67aW1zAEva54NoIB/+GHf6+t1f3ZtbS+5BtP5CawI0XJAA8ulDnwyi+uVbvv79/E62nm2ttdcvNaT0rPz0sDVJC7SkQbbL41ZegUxkO9hAIV12jot1aTNjFYCfj7sPq1CK41ZtJl/ck39rOfBWiXlKNUk5OjowzvSzulSuGSKAQMDopBqkvKm9FVnpaahrrGuwTlF3nDAEG0fKUPMVIoXHgTj5PQoRyHHRd6E6lrEKORt6DP82ZBUqK4xZbKFzRbpseZHNTWYe5I1jE5PDDeGJdiZ9LL8Unr6A0Y+exCoerNBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9336ca39798e1a243905c637637dfd31/e4706/OpenHabian_CLI_04.avif 230w", "/en/static/9336ca39798e1a243905c637637dfd31/d1af7/OpenHabian_CLI_04.avif 460w", "/en/static/9336ca39798e1a243905c637637dfd31/b6582/OpenHabian_CLI_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9336ca39798e1a243905c637637dfd31/a0b58/OpenHabian_CLI_04.webp 230w", "/en/static/9336ca39798e1a243905c637637dfd31/bc10c/OpenHabian_CLI_04.webp 460w", "/en/static/9336ca39798e1a243905c637637dfd31/87ca7/OpenHabian_CLI_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9336ca39798e1a243905c637637dfd31/81c8e/OpenHabian_CLI_04.png 230w", "/en/static/9336ca39798e1a243905c637637dfd31/08a84/OpenHabian_CLI_04.png 460w", "/en/static/9336ca39798e1a243905c637637dfd31/e9beb/OpenHabian_CLI_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9336ca39798e1a243905c637637dfd31/e9beb/OpenHabian_CLI_04.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And all done - your Node-RED installation should now be available under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://openhabpi:1880`}</code>{` (or use the IP address of your Raspberry Pi instead of `}<em parentName="p">{`openhabpi`}</em>{`). All `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/Projects/"
      }}>{`Node-RED Projects`}</a>{` that we developed earlier can now be copy&pasted here.`}</p>
    <h2 {...{
      "id": "setting-up-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-node-red",
        "aria-label": "setting up node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up Node-RED`}</h2>
    <p>{`We already set up `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/Node-RED_Installation_on_Windows/#configuring-nodered"
      }}>{`Node-RED for Windows`}</a>{` - you can simply copy your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setting.js`}</code>{` file and use it on your Pi. The File is located under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/home/openhabian/.node-red/settings.js`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`exports `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    uiPort`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` process`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`PORT`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1880`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    mqttReconnectTime`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    serialReconnectTime`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    debugMaxLength`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    httpAdminRoot`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/admin'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    httpStatic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/home/openhabian/.node-red/static/'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    ui`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` path`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dashboard"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    adminAuth`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"credentials"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        users`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            password`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"$2a$08$ffVDwvP4ZnPF3GVujYy7B.mEW4UIlb4NwuUsqikdgvUXvxyYg16.m"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            permissions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"*"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            permissions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"read"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We changed the admin panel to be available under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/admin`}</code>{`, the dashboard to be under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/dashboard`}</code>{` and set up an admin login using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/#creating-an-adminstrator-login"
      }}>{`node-red-admin tool`}</a>{`. Note that we changed the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/#creating-a-public-directory"
      }}>{`public directory`}</a>{`.`}</p>
    <h3 {...{
      "id": "troubleshooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#troubleshooting",
        "aria-label": "troubleshooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting`}</h3>
    <p>{`You copied everything into the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`settings.js`}</code>{` file but your Node-RED installation is not using it - e.g. you can still open the admin panel on the web root `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/`}</code>{` instead of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/admin`}</code>{` and you are not asked to log in before making changes to your Node-RED flows.`}</p>
    <p>{`The Node-RED installation script installs a start-up script for Node-RED that uses SystemD to manage the task. This script is located under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/lib/systemd/system/nodered.service`}</code>{`. Please make sure that the User, Group and WorkingDirectory is set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openhabian`}</code>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/home/openhabian`}</code>{` in case of the directory) and `}<strong parentName="p">{`NOT`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`root`}</code>{`. The file should look something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# systemd service file to start Node-RED`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Unit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
Description=Node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`RED graphical event wiring tool
Wants=network.target
Documentation=http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//nodered.org/docs/hardware/raspberrypi.html

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
Type=simple
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Run as normal pi user - change to the user name you wish to run Node-RED as`}</span>{`
User=openhabian
Group=openhabian
WorkingDirectory=/home/openhabian

Nice=5
Environment="NODE_OPTIONS=`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`max_old_space_size=256"
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# uncomment and edit next line if you need an http proxy`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Environment="HTTP_PROXY=my.httpproxy.server.address"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# uncomment the next line for a more verbose log output`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Environment="NODE_RED_OPTIONS=-v"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#ExecStart=/usr/bin/env node $NODE_OPTIONS red.js $NODE_RED_OPTIONS`}</span>{`
ExecStart=/usr/bin/env node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`red`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`pi $NODE_OPTIONS $NODE_RED_OPTIONS
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Use SIGINT to stop`}</span>{`
KillSignal=SIGINT
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Auto restart on crash`}</span>{`
Restart=on`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`failure
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Tag things in the log`}</span>{`
SyslogIdentifier=Node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`RED
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#StandardOutput=syslog`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Install`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
WantedBy=multi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.target`}</code></pre></div>
    <h2 {...{
      "id": "manual-installation-optional",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-installation-optional",
        "aria-label": "manual installation optional permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual Installation (optional)`}</h2>
    <p>{`If you are not using the openHABian Linux distro on your Pi, you can install or upgrade Node-RED using the following upgrade script command from the `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/hardware/raspberrypi"
      }}>{`Node-RED Installation Guide`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`bash`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -sL https://raw.githubusercontent.com/node-red/raspbian-deb-package/master/resources/update-nodejs-and-nodered`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f8d79a7323de2f6ccd746da5193a14cc/e9beb/OpenHabian_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "96.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADLUlEQVQ4yy2T2Y7cNhBF9Sn2tCTupNSiSFHcqaV7ZjxtJ5jYCRwYeUjy/58QSA5wHsiHqrrFe1n9s27fb7cfq/+x+b929/fdfy/+z1L+COF3578599Xaz0P/bqbfzPQ+qXczfbP2i5n/lbJapUxqzFLGcchqWPWwarkqmeW1yD72wjLiOPGCxV6kq3CcGIJHQgNClY7ZrouOwa2L2xa/LXNOozXKTXMOc7SuxNFONvuwHNfBGi77hqCG4srnnLYt3/bt+V62JS0xlpSXXJbkgrXOuGB9cJNRahr7oSOcMEEJw5jCKqxL3De/Lq5kl3zM4cSHaEd11WY0Vmsj1SSnWc/OUE4whSeoivvq12VOMSxpve2zcyH7kFwqMWaflljWGJOTY3+Voh84gDWAF4AahNsq7dt6v5V9CTmUNS/Hwec1+eSlGqZZzVaLjmIKKceYghY8AVhDAhABlQnepuBTMM5MdpLqKvUwTqMyigkCUANQ04ILxA2iCFFIji6Hckjaqtw3l2Ncsy9hdvOopXHaReujG/UwW2Xs1F0F5YR3lAmCGUIEQAwO2S4HX6Iv8ZhfknFWmTGW6JMfZK/NmEp0wZ4qaohbRABADcQtJrAq+/L89rK/3HwOPgcXveh5g5sGty2FDWpO6mMUhYhAdAg+Nm/aj9XL4+Xx6+P18breNp+8MgpRJOElCboOneR46ujAMSAAM/x/PUMtrFtwqfaX2+PL2+OXt2Vf85anWV3gJYPLV4rfCRqayxXUtPnYogYzdIIPKD583u7bp8+fnl/v+31JOXRXAXALOahx2xAAKAQUQnoYQzghgmB+QtEZkhLzmvKaQ3JSD0wQJojoj4elHDNBEAEtrBtYA9wetsH6p2YA66ofOt4xygnlpLsKTGHXMy6I6KgQhHPEKKzbp7r5WDcfLvWHBjxB0hJ29K18dC7aUQ96krOb1DSeCRnHScKeE3Vte3Zpn37SwpoJynvGOsYEraZZ5SX66EMOuSTl5iGHPgWzxM6bfok0WqwHajW3mhp1zqRUECpINUevvZmjmqO0cZyjdElbLYaxs1aZ+eBM+KT08bdYxwgnZzH9D65D7RtTA111AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f8d79a7323de2f6ccd746da5193a14cc/e4706/OpenHabian_05.avif 230w", "/en/static/f8d79a7323de2f6ccd746da5193a14cc/d1af7/OpenHabian_05.avif 460w", "/en/static/f8d79a7323de2f6ccd746da5193a14cc/b6582/OpenHabian_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f8d79a7323de2f6ccd746da5193a14cc/a0b58/OpenHabian_05.webp 230w", "/en/static/f8d79a7323de2f6ccd746da5193a14cc/bc10c/OpenHabian_05.webp 460w", "/en/static/f8d79a7323de2f6ccd746da5193a14cc/87ca7/OpenHabian_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f8d79a7323de2f6ccd746da5193a14cc/81c8e/OpenHabian_05.png 230w", "/en/static/f8d79a7323de2f6ccd746da5193a14cc/08a84/OpenHabian_05.png 460w", "/en/static/f8d79a7323de2f6ccd746da5193a14cc/e9beb/OpenHabian_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f8d79a7323de2f6ccd746da5193a14cc/e9beb/OpenHabian_05.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can start Node-RED with the following command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red-start`}</code>{` - and stop it using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red-stop`}</code>{`. To see the log, run the command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red-log`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/e9beb/OpenHabian_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeElEQVQY0zXNWZKjIAAAUM/SSWQVBZFFQFBQEztLpbt6Zu5/kqn5mHeBV/mS+Tgqp4xn0jEdWh2YtKRTHe057gUdeiIFH4RUXMh2UALhy6X+qMGp2m6b5EwQRAlGDUEEQgQJY4giAM81PJMGAnCq649LfYIYIAIAOkN0wRRWt8dR9nI99phcCDYt0zSHlFNIfs7RBxuii8s0Jb+sSdvBBStkRxmmLa3ympvRshigUUhLQgChqGlJ02AATgjXvRJucj66vC5i4ITh//+ler9f9TLD1xM+H+jYQUcBZ60zeBAnAlBL1+v6+nren8fn47bftnVbfBzF8C+vrseO/UjWjPdCy4xzqqOHKcB5qoOFVllnyp7DNMZ5Sku03sR56gfedrR6vJ95y+t1XbZSrmUpc0h+UFwZqY10wZpRKd0rI/w0xiXGeRp03wrW8qb69efn/rp///7eji3maJ32wfZDp3VvR+W8MVZpI1nDlFZzSVKJlrNWsKajfwGcTGcowoO0eQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/e4706/OpenHabian_06.avif 230w", "/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/d1af7/OpenHabian_06.avif 460w", "/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/b6582/OpenHabian_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/a0b58/OpenHabian_06.webp 230w", "/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/bc10c/OpenHabian_06.webp 460w", "/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/87ca7/OpenHabian_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/81c8e/OpenHabian_06.png 230w", "/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/08a84/OpenHabian_06.png 460w", "/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/e9beb/OpenHabian_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f7ab1e8f0643aaeb96ea23deef8e68c4/e9beb/OpenHabian_06.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can also activate a SystemD job to automatically restart Node-RED when you restart your Pi using the command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo systemctl enable nodered.service`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8b28ad961fb82250f59f070be75e0f5b/e9beb/OpenHabian_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.173913043478262%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAbklEQVQI1yXLQRKDIAwAQD/UaiMqkECiRUsFgZn+/y89uPftUsshHvsZkJcFFfJMqybRxIjiLJMVQnEkzq+eN09Mhox1Biboyq9+S7paSvXILV4t5vo5yy7B2zszGnbvzRCOSs+gXjBBD/1zePwBK8Idfya9A6AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b28ad961fb82250f59f070be75e0f5b/e4706/OpenHabian_07.avif 230w", "/en/static/8b28ad961fb82250f59f070be75e0f5b/d1af7/OpenHabian_07.avif 460w", "/en/static/8b28ad961fb82250f59f070be75e0f5b/b6582/OpenHabian_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8b28ad961fb82250f59f070be75e0f5b/a0b58/OpenHabian_07.webp 230w", "/en/static/8b28ad961fb82250f59f070be75e0f5b/bc10c/OpenHabian_07.webp 460w", "/en/static/8b28ad961fb82250f59f070be75e0f5b/87ca7/OpenHabian_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b28ad961fb82250f59f070be75e0f5b/81c8e/OpenHabian_07.png 230w", "/en/static/8b28ad961fb82250f59f070be75e0f5b/08a84/OpenHabian_07.png 460w", "/en/static/8b28ad961fb82250f59f070be75e0f5b/e9beb/OpenHabian_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8b28ad961fb82250f59f070be75e0f5b/e9beb/OpenHabian_07.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      